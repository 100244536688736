body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App-header {
  text-align: center;
  background-color: #3ca3e1;
  padding: 20px;
  color: white;
}

.App-logo {
  max-width: 100%;
}

.App-title {
  font-size: 1.5em;
}


.lead-name {
	font-size: 1.25em;
}

.lead-email {
	font-size: 1em;
}

